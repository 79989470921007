<template>
    <fragment>
        <img :src="avatar" :alt="userName" v-if="avatar"/>
        <img src="/images/avatar.jpg" v-else :alt="userName">
    </fragment>
</template>

<script>
export default {
    props: {
        avatarURL: String,
        altName: String
    }, 
    data() {
        return {
            avatar: '',
            userName: ''
        }
    },
    created: function() {
        this.avatar = this.$props.avatarURL
        this.userName = this.$props.altName
    },
    watch: {
        "$props.avatarURL"() {
            this.avatar = this.$props.avatarURL
        }
    }
}
</script>