<template>
    <header class="header " uk-sticky="top:20 ; cls-active:header-sticky">
        <div class="container">
            <nav uk-navbar class="uk-navbar">
                <!-- left Side Content -->
                <span class="btn-mobile" @click="enableMobileMenu"></span>
                <div class="uk-navbar-left">
                    <!-- breadcrumbs -->                        
                    <!-- <Breadcrumb/> -->
                    <span @click="setLanguage(item.code, true)" v-for="(item, index) of languageData" :key="index" class="language-bar"> 
                        {{ item.language }} <span v-if="index < 2">|</span>
                    </span> 
                </div>

                <!--  Right Side Content  -->
                <div class="uk-navbar-right">
                    <div class="header-widget">                        
                        <span class="icon-feather-x icon-small uk-hidden@s"
                            uk-toggle="target: .header-widget ; cls: is-active"> </span>
                        <a href="javascript:void(0);" role="button" class="header-widget-icon profile-icon" @click="showUserDropdown">
                             <Avatar :altName="userName" :avatarURL="userAvatar" /> <i class="uil uil-angle-down"></i>
                        </a>

                        <div class="dropdown-notifications small uk-dropdown"  style="right: 0px; top: 37px;">
                            <div class="dropdown-user-details">
                                <div class="dropdown-user-avatar">
                                    <Avatar :altName="userName" :avatarURL="userAvatar" />
                                </div>
                                <div class="dropdown-user-name">
                                    {{ userName }} <span>{{ pageContent('student') }}</span>
                                </div>
                            </div>

                            <!-- User menu -->
                            <ul class="dropdown-user-menu">
                                <li>
                                    <router-link to="/user/dashboard">
                                        <i class="icon-material-outline-dashboard"></i> {{ pageContent('dashboard') }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/user/favourite-courses">
                                        <i class="favourite icon-material-outline-favorite"></i> {{ pageContent('favourite_courses') }} 
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/user/profile">
                                        <i class="icon-feather-settings"></i> {{ pageContent('account_settings') }}
                                    </router-link>
                                </li>                                  
                                <li class="menu-divider"></li>
                                <li>
                                    <router-link to="/user/change-password">
                                        <i class="icon-feather-lock"></i> {{ pageContent('change_password') }}
                                    </router-link>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" @click="userLogout"> <i class="icon-feather-log-out"></i> {{ pageContent('logout') }}</a>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <a style="display:none;"
                        class="uk-navbar-toggle uk-hidden@s"
                        uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="#">
                        <i class="uil-search icon-small"></i>
                    </a>
                </div>                
            </nav>
        </div>
        <!-- container  / End -->
    </header>
</template>

<script>
import Avatar from '../Students/Avatar'
import Breadcrumb from '../Students/Breadcrumb'
import Translation from '../../../public/translation.json'

export default { 
    components: {Avatar, Breadcrumb}, 
    data() {
        return {
            dropdownClass: '',
            userData: localStorage.getItem('_howofwhat_auth_user') || '',
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            languageData: []
        }
    },
    computed:{
        displayName(){
            return this.$store.getters.userName.split(' ')[0]
        },
        userName() {
            return this.$store.getters.userName
        },
        userEmail() {
            return this.$store.getters.userEmail
        },
        userPhone() {
            return this.$store.getters.userPhone
        },
        userAvatar() {
            return this.$store.state.auth.avatar
        },
        specialAccount() {
            return this.$store.getters.userSpecial
        }
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        enableMobileMenu(){
            if(document.querySelector('#wrapper').classList.length < 1)
                document.querySelector('#wrapper').classList.add('mobile-active')
            else
                document.querySelector('#wrapper').classList.remove('mobile-active')
        },
        userLogout(){
            this.$store.dispatch('logout').then(res=>{
                window.location.href = '/'
            })
            .catch(error => console.log('Error:' +error.response.data.error))
        },
        stickyMenu(){
            if(document.documentElement.scrollTop > 50){
                (document.querySelector('.header') !== null) ? document.querySelector('.header').classList.add('uk-sticky', 'header-sticky') : ''
            }
            else  {             
                (document.querySelector('.header') !== null) ? document.querySelector('.header').classList.remove('uk-sticky', 'header-sticky') : ''
            }
        },
        showUserDropdown() {
            document.querySelector('.dropdown-notifications').classList.toggle('uk-open')
        },
        getLanguage(){
            this.$http.get(`languages`).then(res => {
                if(res.data.status === true) {
                    this.languageData = res.data.data
                    if(!localStorage.getItem('_store_lang')) {
                        this.languageData.map(item=>{
                            if(item.default_language === 'Y') {
                                this.defaultLanguage = item.code
                                this.setLanguage(this.defaultLanguage, false)
                                return false
                            }
                        })
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        setLanguage(lang, reload){
            if(!lang) return;
            this.defaultLanguage = lang
            localStorage.setItem('_store_lang', this.defaultLanguage)
            if(reload === true){
                location.reload()
            }
        }
    },
    created: function(){
        this.getLanguage()
    },
    mounted: function(){
        window.addEventListener('scroll', this.stickyMenu); 
    }
}
</script>

<style>
.language-bar {
    display: inline-block;
    margin: 2px 4px 0;
    line-height: 19px;
    vertical-align: top;
    cursor: pointer;
}
.language-bar:hover {
    color: #6253f1;
}
@media screen and (max-width: 420px) {
    .language-bar{font-size: 11px; margin: 2px 4px 0;}
    .profile-icon{font-size: 11px;}
}
@media screen and (max-width: 650px) {
    .dropdown-user-name{font-size: 12px !important;}
}
</style>